.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo{
  width: 120px;
  height: 120PX;

}

.about_img {
 width: 500px;
}

#Thumbnail {
  padding: 0%;
  width: 500px;
}
#roadmap{
  padding: 150px;
}
.polygon-bg {
  width: 40px;
  height: 40px;
  background:#01a3f4; /* Facebook blue */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  color: white;
  margin: 0%;
  align-items: center;
  
}

.polygon-bg:hover {
  color: #000;
  transform: scale(0.8); 
}

.footer{
  display: block;
}

.left_part p {
  text-align: center!important;
}
.countdown-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow boxes to wrap to the next line on smaller screens */
}

.countdown-box {
  text-align: center;
  width: calc(25% - 20px); /* Adjust the width based on the number of boxes per row */
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  color:#000000;
  background-color: #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.countdown-box span {
  font-size: 38px;
  display: block;
  margin-bottom: 5px;
}

.countdown-box .value {
  font-weight: bold;
}

.countdown-box .label {
  font-size: 16px;
  color: #555;
  font-weight: 400;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  

  .countdown-box {
    width: calc(25% - 20px); /* Four boxes per row on mobile */
    padding: 0px;
  }
  #roadmap{
    padding: 10px;
  }
  .logo {
    height: 0px;
  }
  .container1 {
    width: 100% !important;
    max-width: 1280px;
    padding: 0 40px !important;
    margin: 0 auto;
  }
  .fn_col22{
    width: 100% !important;
  }
}
.center{
  text-align: center;
}

.centers{
  text-align: center;
  color: white;
}
.hero_header .buttons1 {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  align-content: center;
  justify-content: center;
}


.details-column {
 
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.details-column h2 {
  font-size: 1.9rem;
  margin-bottom: 20px;
  font-weight: 700;
  color:White;
  text-align: center;
  margin-top: 10px;
}

.details-column table {
  width: 100%;
  border-collapse: collapse;
  
}

.details-column table td,
.details-column table th {
  padding: 10px;
  border: 2px solid white;
  
}

.details-column table td:first-child {
  font-weight: bold;
  color: white;
  background: black;
  border: #0062f9 solid 3px;
  width: 40%;
}

.details-column table td:nth-child(2) {
  width: 60%;
  color: black;
  background: white;
  border: #0062f9 solid 3px;
}

/* Additional styling can be added as needed */

.fn_col22 {
  width: 50%;
  align-content: center;
  
}
.container1 {
  width: 50%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
}
.fn_col29{
  width: 100%;
}

#tokenomics{
  padding: 150px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
}
.titlemall{
    font-size: 32px;
    padding-top: 4px;
    text-align: center;
    font-weight: 200;
}
.metaverse_fn_button .text1{
  color: black;
}
.helpfuls{
  display: flex;
  column-gap: 10px;
}



/*New roadmap css*/


.roadmaps {
  padding: 30px 50px;
  
  .point {
    display: flex;
    flex-direction: column;
    padding: 10px 50px;
    position: relative;
    
    &:first-child {
      .point-index {
        top: auto;
        bottom: 0;
        transform: translate(-50%, 2px) !important;
      }
    }    
    
    &:last-child {
      .point-index {
        top: 0;
        transform: translate(50%, -2px) !important;
      }
    }   
    
    &:not(:last-child) {
      border-bottom: 5px solid;
    }

    &:nth-child(odd) {
      align-items: flex-start;
      
      &:not(:first-child) {
        border-left: 5px solid;
      }
      
      .point-index {
        left: 0;
        transform: translate(-50%, -50%);
      }
    }
    
    &:nth-child(even) {
      align-items: flex-end;
      
      &:not(:last-child) {
        border-right: 5px solid #FFFFFF;
      }
      
      .point-index {
        right: 0;
        transform: translate(50%, -50%);
      }
    }
    
    .point-index {
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      background: #151317;
      border: 2px solid #FFFFFF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 23px;
      font-weight: bold;
    }
    
    .point-label {
      flex: 1 0 100%;
      width: 100%;
      font-size: 22px;
      color: black;
      font-weight: 700;
      margin-bottom: 5px;
      
      @media (min-width: 641px) {
        flex: 1 0 50%;
        width: 50%;
      }
    }
    
    .point-text {
      flex: 1 0 100%;
      width: 100%;
      font-size: 15px;
      color: darken(#FFFFFF, 20%);
      
      @media (min-width: 641px) {
        flex: 1 0 50%;
        width: 50%;
      }
    }
  }
}

/*Goals css*/

/* Container styling */
.goals-container {
  background-color: #f9f9f9;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* Title styling */
#goals{
  padding: 100px 0;
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}
.goals-title-text {
  margin: 0;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 1.1;
  text-align: center;
  color: black;
}

.goals-divider {
  text-align: center;
  margin-bottom: 20px;
}

.goals-line {
  display: inline-block;
  width: 446px;
  height: 4px;
  background-color: #333;
}

/* Row and Column styling */
.goals-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.goals-column {
  flex: 1 1 calc(33.333% - 20px);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: black 2px solid;
}

.goals-column p {
  margin: 0;
  font-size: 16px;
  color: #666;
}

/* Responsive design */
@media (max-width: 768px) {
  .goals-column {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .goals-column {
    flex: 1 1 100%;
  }
}
